
.wrapper{
  background: linear-gradient(82.16deg, #33def2 24.72%, #32d8f1 30.43%, #31c8ef 37.76%, #2eadec 46.13%, #2b87e7 55.17%, #2657e0 64.77%, #211dd9 74.67%, #1e00d5 79.23%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

}

.animatedPath {
  stroke-dasharray: 143;
  stroke-dashoffset: 143;
  stroke-linecap: round;
  animation: dash 2s linear forwards infinite;
  animation-timing-function: cubic-bezier(0.5, 0.75, 0.2, 1.01);
}

@keyframes dash {

  from {
    stroke-dashoffset: 140;
    stroke-dasharray: 80;
  }

  to {
    stroke-dashoffset: 0;
    stroke-dasharray: 160;
  }
}