.fullScreenLoaderWrapper{
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.fullScreenLoader{
  text-align: center;
  font-size: 16px;
  text-shadow: 0 1px 2px rgb(0 0 0 / 50%) !important;
}

.screenLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px auto;
}